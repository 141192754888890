<template>
  <div>
    <b-modal
      v-model="isForgetPasswordModal"
      content-class="login-modal"
      hide-footer
      header-class="login-modal-header"
      body-class="login-modal-body"
    >
      <template #modal-header>
        <div 
          @click="isForgetPasswordModal = false"
          class="login-modal-header--close-icon"
          style="float: left"
        >
          <img 
            :src="require('@/assets/images/pictos/croix.svg')"
          />
        </div>
        <div
          class="login-modal-header--title"
          style=""
        >
          Password assistance
        </div>
  
      </template>
      <div class="mt-2 text-center">
        <strong> Enter the email address associated with your AIRspirit account. </strong>
      </div>

      <b-form 
        @submit.prevent="sendForgetPasswordEmail"
        class="mt-3"
      >
        <input
          class="registration-input login-input"
          type="text"
          maxlength="255"
          placeholder="Email"
          v-model="email"
          :state="$v.email.$dirty ? !$v.email.$error : null"
        >
        <button
          class="login-button mt-3"
          :disabled="$v.$invalid"
        >
          Reset password
        </button>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import { AuthAPIService } from '@/api/APIAuth'
import { validationMixin } from 'vuelidate'
import { required, email } from 'vuelidate/lib/validators'


const apiAuth = new AuthAPIService()


export default {
  name: 'ForgetPasswordModalComponent',
  mixins: [
    validationMixin
  ],
  data: function () {
    return {
      email: '',
    }
  },
  computed : {
    isForgetPasswordModal: {
      get () {
        return this.$store.state.isForgetPasswordModal
      },
      set () {
        this.$store.commit('closeForgetPasswordModal')
      }
    },
  },
  validations: {
    email: {
      required,
      email
    },
  },
  methods: {
    sendForgetPasswordEmail: function () {
      this.$store.commit('loadFromAPI')
      apiAuth.resetPassword({email: this.email})
      .then(() => {
        this.$root.$bvToast.toast(`An email to reset your passwor has been sent`, {
          title: 'Email sent',
          autoHideDelay: 5000,
          appendToast: false,
          variant: "success"
        })
        this.$store.commit('closeForgetPasswordModal')
      })
      .catch((error) => {
        throw new Error('sendForgetPasswordEmail sendForgetPasswordEmail API Error : ' + String(error))
      })
      .finally(() => {
        this.$store.commit('endLoadingFromAPI')
      })
    }
  }
}
</script>

<style>
.login-modal {
  border-radius: 10px !important;
}
.login-modal-header {
  display: block !important;
}

.login-modal-header--close-icon {
  cursor: pointer;
  padding: 10px;
  border-radius: 50%;
}

.login-modal-header--close-icon:hover {
  background-color: #DEDEDE;
}


.login-modal-header--close-icon > img {
  height: 10px;
  display: block;
  margin: auto;
}

.login-modal-header--title {
  font-size: 1.1rem;
  font-weight: bold;
  margin: 0 auto; 
  text-align: center;
  width: 300px;
}

.login-modal-body {
  padding: 10px 30px 30px 30px !important;
}

.login-input {
  border-radius: 5px !important;
}

.login-button {
  background-color: white;
  border: 1px solid black;
  border-radius: 5px;
  color: black;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 30px;
  padding-right: 30px;
  font-weight: bold;
  font-size: 1rem;
  width: 100%;
}

.or-center-line {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  margin-top: 30px;
}

.or-line {
  flex-grow: 1;
  height: 1px;
  background-color: #D9D9D8;
}

.or {
  color: #D9D9D8;
  margin: 0 10px;
}
</style>
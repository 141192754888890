import { getField, updateField } from 'vuex-map-fields';

const state = {
  // GLOBAL
  companyRegistrationStep: localStorage.getItem('airspirit-registration-companyRegistrationStep') || 1,
  companyRegistrationSubStep: localStorage.getItem('airspirit-registration-companyRegistrationSubStep') || 1,

  //// ------------ COMPANY -------------------
  company: {
    category: localStorage.getItem('airspirit-registration-company-category') || null,
    name: '',
    user: {
        firstName: '',
        lastName: '',
        username: '',
        password: '',
        passwordConfirmation: '',
    }
  }
}

const getters = {
  getField,
  getCompanyRegistrationForm: state => {
    return {
        categories: [{name: state.company.category}],
        operator_categories: [],
        qualifications: [],
        name: state.company.name,
        user: {
            first_name: state.company.user.firstName,
            last_name: state.company.user.lastName,
            username: state.company.user.username,
            password: state.company.user.password,
        }
    }
  }
}

const mutations = {
  updateFieldWithLocalStorage(state, field) {
    if (Array.isArray(field.value) || typeof field.value === 'boolean') {
    localStorage.setItem(`airspirit-registration-${field.path.replaceAll('.', '-')}`, JSON.stringify(field.value))
    }
    else {
    localStorage.setItem(`airspirit-registration-${field.path.replaceAll('.', '-')}`, field.value)
    }
    updateField(state, field);
  },
}

export default {
  state,
  getters,
  mutations,
}
<template>
  <div>
    <b-modal
      v-model="isAutoPromoModal"
      content-class="airspirit-modal"
      hide-footer
      hide-header
      header-class="airspirit-modal-header"
      body-class="newsletter-subscriber-modal-body pl-4 pr-4"
      size="md"
    >
        <div 
          @click="isAutoPromoModal = false"
          class="airspirit-modal-header--close-icon mt-2"
          style="float: left"
        >
          <img 
            :src="require('@/assets/images/pictos/croix.svg')"
          />
        </div>
        
        <div>
          <div
            class="airspirit-modal-header--title mt-4"
            style=""
          >
            We Need You! Support AIRspirit's Mission
          </div>
          <div
            class="text-center mt-4"
          >
            <img
              src="@/assets/images/donation/autopromo_icon.svg"
              width="150px;"
            />
          </div>
          <div class="text-center mt-4">
            We're building the ultimate General Aviation marketplace with free tools for pilots & crews, 
            operators, brokers, students, and beyond. Help us innovate and keep these features free for the Aviation community.
          </div>
          <button
            class="profile-delete-button mt-4 w-100"
            @click="clickOnDonate"
          >
            Donate Now
          </button>
        </div>
    </b-modal>
  </div>
</template>

<script>
import { APIDonation } from '@/api/APIDonation'

const apiDonation = new APIDonation()


export default {
  name: 'AutoPromoModal',
  data () {
    return {
      isAutoPromoModal: false,
    }
  },
  computed: {
    routeName(){
      console.log(this.$route)
      return this.$route.name
    },
  },
  created () {
    const pathName = window.location.pathname
    if (!pathName.includes('admin')){
      this.getAutoPromoSettings()
    }
  },
  methods: {
    clickOnDonate () {
      this.isAutoPromoModal = false
      this.$store.commit('openDonationModal')
    },
    getAutoPromoSettings () {
      apiDonation.getAutoPromoSettings()
        .then(response => {
          this.isAutoPromoModal = response.data.open_autopromo
        })
        .catch(error => {
          console.error(error)
        })
    },
  }
  
}
</script>
import axios from 'axios'
import { apiBaseUrl } from '@/variables/localVariables'

export class AuthAPIService {

  confirmResetPassword ({token, password}) {
    console.log(token)
    console.log(password)
    const url = `${apiBaseUrl}/auth/password/reset/confirm/`
    var headers = {
      'Content-Type': 'application/json'
    }
    return axios.post(
      url,
      {
        'token': token,
        'password': password
      },
      headers
    )
  }

  createCustomerServiceDemand(token, contact) {
    const url = apiBaseUrl + `/customer-service/contact/`
    const headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json'
    }
    return axios.post(url, contact, {headers: headers})
  }

  getCurrentUser (token) {
    const url =  `${apiBaseUrl}/users/current/`
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json'
    }
    return axios.get(
      url,
      {
        'headers': headers,
      }
    )
  }

  getToken (user) {
    const url = apiBaseUrl + `/auth/token/`
    return axios.post(url, user)
  }

  modifyPassword (token, old_password, new_password, new_password_confirmed) {
    const url = apiBaseUrl + `/modify-password/`
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json'
    }
    var data = {
      'old_password': old_password,
      'new_password': new_password,
      'new_password_confirmed': new_password_confirmed,
    }

    return axios.put(
      url, data,
      {
        'headers': headers,
      }
    )
  }

  modifyUser(token, data) {
    const url =  `${apiBaseUrl}/users/current/`
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json'
    }
    return axios.put(
      url, data,
      {
        'headers': headers,
      }
    )
  }

  refreshToken (refreshToken) {
    const url = apiBaseUrl + `/auth/token/refresh/`
    return axios.post(url, {'refresh': refreshToken})
  }

  resetPassword ({email}) {
    const url = `${apiBaseUrl}/auth/password/reset/`
    return axios.post(url, {email})
  }
}

import axios from 'axios'
import { apiBaseUrl } from '@/variables/localVariables'

const HEADERS = {
  'Content-Type': 'application/json',
}
export class APIDonation {

  confirmDonation (data) {
    let url = `${apiBaseUrl}/donation/confirm/`
    return axios.post(url, data, {'headers': HEADERS,})
  }

  createOrder(data) {
    let url = `${apiBaseUrl}/donation/create-order/`
    return axios.post(url, data, {'headers': HEADERS,})
  }

  getAutoPromoSettings () {
    let url = `${apiBaseUrl}/auto-promo-settings/`
    return axios.get(url, {'headers': HEADERS,})
  }


}

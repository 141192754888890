<template>
  <div>
    <b-modal
      v-model="showLanguageMenuModal"
      content-class="language-menu-modal"
      hide-footer
      header-class="language-menu-modal-header"
      body-class="language-menu-modal-body"
    >
      <template #modal-header>
        <div 
          @click="showLanguageMenuModal = false"
          class="language-menu-modal-header--close-icon"
          style="float: left"
        >
          <img 
            :src="require('@/assets/images/pictos/croix.svg')"
            alt="close"
          />
        </div>
        <div
          class="language-menu-modal-header--title"
          style=""
        >
          {{ $t('navbar.language.choose_your_language') }}
        </div>
  
      </template>
      <div class="mt-2">
        
        <div class="d-flex justify-content-between mt-3">
          <label
            for="english-language"
          >
            {{ $t('navbar.language.english') }}
          </label>
          <b-form-radio
            v-model="currentLocale"
            id="english-language"
            name="english-language" 
            value="en" 
            @change="switchLocale">
          </b-form-radio>
        </div>

        <div class="d-flex justify-content-between mt-3">
          <label for="francais-language">
            {{ $t('navbar.language.french') }}
          </label>
          <b-form-radio
            v-model="currentLocale"
            id="francais-language"
            name="francais-language" 
            value="fr" 
            @change="switchLocale">
          </b-form-radio>
        </div>

        <div class="d-flex justify-content-between mt-3">
          <label for="german-language">
            {{ $t('navbar.language.german') }}
          </label>
          <b-form-radio
            v-model="currentLocale"
            id="german-language"
            name="german-language" 
            value="de" 
            @change="switchLocale">
          </b-form-radio>
        </div>

        <div class="d-flex justify-content-between mt-3">
          <label for="spanish-language">
            {{ $t('navbar.language.spanish') }}
          </label>
          <b-form-radio
            v-model="currentLocale"
            id="spanish-language"
            name="spanish-language" 
            value="es" 
            @change="switchLocale">
          </b-form-radio>
        </div>

      </div>
    </b-modal>


    <nav
      v-if="!isMobile()"
    >
      <div id="basic-navbar">
        <div class="basic-navbar-left" >
          <img 
            @click="$router.push('/')"
            class="logo-img"
            src="@/assets/images/logo_x1jets.png" 
            alt="airspirit" 
          />
        </div>
        <div class="basic-navbar-center">
          <div 
            class="nav-menu-desktop"
            :style="navBarSearchBarStyle"
          >
            <div
              v-if="section !== 'chat'"
              class="nav-menu-desktop-item" style="border-right: 1px solid rgb(163, 163, 163);"
            >
              <a
                @click="goToPage('ads')"
                :class="{ 'nav-menu-desktop-item-link--not-active': section !== 'ads' }"
              >
                {{ $t("navbar.ads") }}

              </a>
            </div>
            <div 
              v-if="section !== 'chat'"
              class="nav-menu-desktop-item" 
              style="border-right: 1px solid rgb(163, 163, 163);"
            >
              <a
                @click="goToPage('map')"
                :class="{ 'nav-menu-desktop-item-link--not-active': section !== 'map' }"
              >
                {{ $t("navbar.map") }}
              </a>
            </div>
            <div
              v-if="section !== 'chat'"
              class="nav-menu-desktop-item"
            >
              <a
                @click="goToPage('news')"
                :class="{ 'nav-menu-desktop-item-link--not-active': section !== 'news' }"
              >
                {{ $t("navbar.news") }}
              </a>
            </div>
            <div 
              v-if="section !== 'home' && section !== 'map' && section !== 'chat'"
              class="nav-menu-desktop-input" 
              style="border-left: 1px solid rgb(163, 163, 163);"
            >
              <input
                v-model="searchText"
                class="nav-menu-desktop-input-text"
                type="text"
                placeholder="Search AIRspirit..."
                @keyup.enter="search"
              />
              <div
                @click="search"
                class="nav-menu-desktop-input-button"
              >
                <img 
                  src="@/assets/images/pictos/picto_search_white.png" 
                  alt="search" 
                />
              </div>
            </div>
            <div 
              v-else
              class="nav-menu-desktop-input" 
              :style="navMenuDesktopInputStyle"
            >
              <input
                v-model="chatMessage"
                @keyup.enter="chat"
                ref="search-menu-mobile-chat-input"
                class="nav-menu-desktop-input-text"
                type="text"
                placeholder="Message AIRspirit..."
                :disabled="disabledChatInput"
              />
              <div
                @click="chat"
                class="nav-menu-desktop-chat-input-button"
              >
                <img 
                  src="@/assets/images/navbar/search_chat.png" 
                  alt="search" 
                />
              </div>
            </div>

          </div>
        </div>
        <div class="basic-navbar-right">
          <div
            style="position: relative;"
          >
            <div 
              class="basic-navbar-right-language-div" 
              @click="toggleLanguage"
            >
              <img 
                class="basic-navbar-picto-img"
                src="@/assets/images/pictos/picto_world.png" 
                alt="languages" 
              />
            </div>
          </div>

          <div 
            class="basic-navbar-right-language-div" 
            @click="clickOnLogin"
          >
            <img 
              class="basic-navbar-picto-img"
              src="@/assets/images/pictos/picto_login.png" 
              alt="login" 
            />
          </div>
        </div>
      </div>
    </nav>
    <div
      v-else
    >
      <div 
        class="search-menu-mobile-input"
        style="display: block"
      >
        <div
          v-if="section !== 'home' && section !== 'map' && section !== 'chat'"
          class="d-flex"
        >
          <div
            class="search-menu-mobile-input-button d-flex"
          >
            <img 
              src="@/assets/images/pictos/picto_search.png" 
              alt="search" 
            />
          </div>
          <input
            v-model="searchText"
            class="search-menu-mobile-input-text"
            type="text"
            placeholder="Search AIRspirit"
            @keyup.enter="search"
          />

          <div
          >
            <img 
              @click="showLanguageMenuModal = true"
              class="language-filter-icon"
              src="@/assets/images/navbar/filtres_icon.png" 
              alt="search" 
            />  
          </div>
          
        </div>

        <div 
          v-else
          class="d-flex" 
        > 
          <div
            class="search-menu-mobile-input-button d-flex"
          >
            <img 
              src="@/assets/images/pictos/picto_search.png" 
              alt="search" 
            />
          </div>
          <input
            v-model="chatMessage"
            @keyup.enter="chat"
            class="search-menu-mobile-input-text"
            type="text"
            placeholder="Message AIRspirit..."
            :disabled="disabledChatInput"
          />

          <div
          >
            <img 
              @click="showLanguageMenuModal = true"
              class="language-filter-icon"
              src="@/assets/images/navbar/filtres_icon.png" 
              alt="search" 
            />  
          </div>
        </div>
      </div>

      <nav
        class="navbar-mobile"
      >
        <div class="navbar-mobile--items">
          <div 
            @click="$router.push('/ads')"
            class="navbar-mobile--item"
          >
            <img
              class="navbar-mobile--item--img"
              :src="require(`@/assets/images/pictos/ads_etiquette_${logoColor('ads')}.svg`)" 
              alt="ads" 
            />
            <div 
              class="navbar-mobile--item--text"
              :style="{ color: textColor('ads') }"
            >
              {{$t('navbar.ads')}}
            </div>
          </div>
          <div 
            @click="$router.push('/map')"
            class="navbar-mobile--item"
          >
            <img
              class="navbar-mobile--item--img"
              :src="require(`@/assets/images/pictos/map_${logoColor('map')}.svg`)" 
              alt="map" 
            />
            <div 
              class="navbar-mobile--item--text"
              :style="{ color: textColor('map') }"
            >
              {{$t('navbar.map')}}
            </div>
          </div>
          <div class="navbar-mobile--item">
            <img 
              class="navbar-mobile--item--logo"
              @click="$router.push('/')"
              src="@/assets/images/logo_x1jets.png" 
              alt="airspirit" 
            />
          </div>
          <div
            @click="$router.push('/news')" 
            class="navbar-mobile--item"
          >
            <img
              class="navbar-mobile--item--img"
              :src="require(`@/assets/images/pictos/news_${logoColor('news')}.svg`)" 
              alt="newsletters" 
            />
            <div 
              class="navbar-mobile--item--text"
              :style="{ color: textColor('news') }"
            >
              {{$t('navbar.news')}}
            </div>
          </div>
          <div 
            @click="clickOnLogin"
            class="navbar-mobile--item"
          >
            <img
              class="navbar-mobile--item--img"
              :src="require(`@/assets/images/pictos/login_${isLoginModal ? 'black' : 'grey'}.svg`)" 
              alt="login" 
            />
            <div 
              class="navbar-mobile--item--text"
              :style="loginTextStyle"
            >
              {{$t('navbar.login')}}
            </div>
          </div>
        </div>
      </nav>
    </div>
  </div>
</template>

<script>
import { mobileDetectionMixins } from '@/mixins/mobileDetection'

export default {
  name: 'BasicNavbar',
  props: {
    displayMenu: {
      type: Boolean,
      default: true,
    },
  },

  mixins: [mobileDetectionMixins],
  data: function () {
    return {
      chatMessage: '',
      currentLocale: "en",
      disabledChatInput: false,
      showLanguageMenuModal: false,
    }
  },
  computed: {
    searchText: {
        get () { 
          return this.$store.state.searchText
        },
        set (value) { 
          this.$store.commit('set', ['searchText', value])
        }
    },
    isAuthenticated () {
      return this.$store.getters.isAuthenticated
    },
    isLoginModal () {
      return this.$store.getters.isLoginModal
    },
    loginTextStyle () {
      if (this.isLoginModal) {
        return { color: 'black' }
      }
      else {
        return { color: '#B1B1B1' }
      }
    },
    navBarSearchBarStyle () {
      if (this.section === 'chat') {
        return { display: 'block' }
      }
      return {display: 'flex'}
    },
    navMenuDesktopInputStyle () {
      if (this.section === 'chat') {
        return { 'border-left': 'none' }
      }
      return {'border-left': '1px solid rgb(163, 163, 163)'}
    },
    section: function () {
      return this.$route.name
    },
    /*searchMenuMobileInputStyle () {
      const style = {
        display: 'flex',
      }
      if (this.section === 'home' || this.section === 'chat' || this.section === 'map' || this.section === 'news') {
        style['display'] = 'block'
      }
      return style
    }*/
  },
  beforeUnmount () {
    this.$eventHub.$off('cleanNavbarInputText')
    this.$eventHub.$off('disableNavbarInputText')
    this.$eventHub.$off('enableNavbarInputText')
  },
  mounted () {
    // Set the initial locale from localStorage or default
    const savedLocale = localStorage.getItem("locale");
    if (savedLocale) {
      this.currentLocale = savedLocale;
      this.$i18n.locale = savedLocale;
    }

    this.$eventHub.$on('setSearch', (searchText) => {
      this.searchText = searchText
    })

    this.$eventHub.$on('cleanNavbarInputText', () => {
      this.chatMessage = ''
      // put input in search-menu-mobile-chat-input
      // nextTicks
      this.$nextTick(() => {
        this.$refs['search-menu-mobile-chat-input'].focus()
      })
    })

    this.$eventHub.$on('disableNavbarInputText', () => {
      this.disabledChatInput = true
    })

    this.$eventHub.$on('enableNavbarInputText', () => {
      this.disabledChatInput = false
    })
  },

  methods: {
    chatHandleSubmit(e) {
      e.preventDefault();
    },
    clickOnLogin () {
      this.$store.commit('closeChat')
      if (this.isAuthenticated) {
        this.$router.push("/account")
      } else {
        this.$store.commit('openLoginModal')
      }
    },
    clickOnFilters () {
      this.$eventHub.$emit('openLanguageMenuModal')
    },
    goToPage(page) {
      this.searchText = ''
      let route = this.$router.resolve({path: `/${page}`})
      window.location.href = route.href
    },
    chat () {
      //this.$refs['search-menu-mobile-chat-input'].blur()
      if (this.section !== 'chat') {
        this.$store.commit('set', ['chatInitialMessage', this.chatMessage])
        this.$router.push('/chat')
      }
      else {
        this.$eventHub.$emit('chat', this.chatMessage)
      }
    },
    logoColor (pathName) {
      if (this.$route.name === pathName) {
        return 'black'
      }
      else {
        return 'grey'
      }
    },
    textColor (pathName) {
      if (this.$route.name === pathName) {
        return 'black'
      }
      else {
        return '#B1B1B1'
      }
    },
    search () {
      this.$store.commit('set', ['search', this.searchText])
      this.$eventHub.$emit('search')
    },
    switchLocale(locale) {
      this.currentLocale = locale;
      this.dropdownOpen = false;

      // Assuming you are using vue-i18n
      this.$i18n.locale = locale;

      // Optional: Store the locale in localStorage to persist user preference
      localStorage.setItem("locale", locale);
    },
    toggleLanguage() {
      if (this.isMobile()) {
        this.showLanguageMenuModal = !this.showLanguageMenuModal
      }
      else {
        this.$emit('toggle-language-menu')
      }
    }
  }
}
</script>

<style>

a {
  color: black;
  text-decoration: none;
}
a:hover {
  color: black;
  text-decoration: none;
}

#basic-navbar {
  display: flex;
  height: 120px;
  background: white;
  padding-left: 5%;
  padding-right: 5%;
  border-bottom: 1px rgba(61, 61, 61, 0.1) solid; /* #3D3D3D (5%) */
}

@media (min-width: 800px) {
  #basic-navbar {
    padding-right: 100px;
    padding-left: 100px;
  }
}

.basic-navbar-left {
  flex: 1;          /* shorthand for: flex-grow: 1, flex-shrink: 1, flex-basis: 0 */
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.basic-navbar-center {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo-img {
  cursor: pointer;
  height: 65px;
}

.basic-navbar-right {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 20px;
}

.basic-navbar-right-language-div {
  padding: 10px;
  border-radius: 50%;
}

.basic-navbar-right-language-div:hover {
  background-color: #DEDEDE;
}
.basic-navbar-right-language-div--disabled:hover {
  background-color: transparent !important;
}

.basic-navbar-picto-img {
  cursor: pointer;
  height: 25px;
  display: block;
  margin: auto;
}

.basic-navbar-picto-img--disabled {
  cursor: auto! important;
}


.nav-menu-desktop {
  border-radius: 50px;
  border: 1px solid rgb(163, 163, 163);
  display: flex;
  justify-content: space-around;
  padding-top: 5px;
  padding-bottom: 5px;
  margin: auto;
  width: 600px;
}
.nav-menu-desktop-item {
  align-items: center;
  color: rgba(255, 255, 255, .3);
  cursor: pointer;
  display: flex;
  font-weight: bold;
  gap: 15px;
  justify-content: center;
  width: 25%;
}

.nav-menu-desktop-item--disabled {
  cursor: auto !important;
}

.nav-menu-desktop-item-link--not-active {
  color: rgb(163, 163, 163);
}

.nav-menu-desktop-input {
  display: flex;
  padding-right: 10px;
  padding-left: 10px;
}

.nav-menu-desktop-input-text {
  border: none;
  outline: none;
  width: 100%;
}
.nav-menu-desktop-input-text:focus {
  border: none;
  outline: none;
}
.nav-menu-desktop-input-text:active {
  border: none;
  outline: none;
}
.nav-menu-desktop-input-text:focus-visible {
  border: none;
  outline: none;
}

.nav-menu-desktop-input-button {
  background-color: black;
  border-radius: 50%;
  cursor: pointer;
  padding: 3px 7px 5px 7px;
}

.nav-menu-desktop-input-button > img {
  width: 20px;
  height: 20px;
}

.nav-menu-desktop-chat-input-button {
  border-radius: 50%;
  cursor: pointer;
  padding: 3px 7px 5px 7px;
}

.nav-menu-desktop-chat-input-button > img {
  width: 25px;
  height: 25px;
}


/* Mobile */
.navbar-mobile {
  background-color: white;
  position: fixed;
  bottom: 0;
  padding: 10px 20px 10px 20px;
  width: 100%;
  z-index: 99999;
}

.navbar-mobile--items {
  display: flex;
  justify-content: space-between;
}

.navbar-mobile--item {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.navbar-mobile--item--img {
  height: 25px;
}

.navbar-mobile--item--logo {
  height: 40px;
}

.navbar-mobile--item--text {
  font-size: 10px;
  font-weight: 600;
  margin-top: 5px;
}

.search-menu-mobile-input {
  border: 1px solid #DDDDDD;
  border-radius: 40px;
  box-shadow: 0 1px 2px rgba(0,0,0,0.08), 0 4px 12px rgba(0,0,0,0.05);
  display: flex;
  margin-left: 30px;
  margin-right: 30px;
  margin-top: 15px;
  padding: 5px 5px 5px 5px;
}

.search-menu-mobile-input-text {
  border: none;
  outline: none;
  width: 100%;
}

.search-menu-mobile-input-text:focus {
  border: none;
  outline: none;
}
.search-menu-mobile-input-text:active {
  border: none;
  outline: none;
}
.search-menu-mobile-input-text:focus-visible {
  border: none;
  outline: none;
}

.search-menu-mobile-input-button {
  cursor: pointer;
  padding: 5px 7px 5px 7px;
}

.search-menu-mobile-input-button > img {
  width: 20px;
  height: 20px;
}

.language-filter-icon {
  cursor: pointer;
  width: 30px;
  height: 30px;
}

.language-dropdown {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  list-style: none;
  padding: 8px 0;
  margin: 0;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.language-dropdown li {
  display: flex;
  align-items: center;
  padding: 8px 16px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.language-dropdown li:hover {
  background: #f0f0f0;
}

.language-menu-modal {
  border-radius: 10px !important;
}
.language-menu-modal-header {
  display: block !important;
}

.language-menu-modal-header--close-icon {
  cursor: pointer;
  padding: 10px;
  border-radius: 50%;
}

.language-menu-modal-header--close-icon:hover {
  background-color: #DEDEDE;
}


.language-menu-modal-header--close-icon > img {
  height: 10px;
  display: block;
  margin: auto;
}

.language-menu-modal-header--title {
  font-size: 1.1rem;
  font-weight: bold;
  margin: 0 auto; 
  text-align: center;
  width: 300px;
}

.language-menu-modal-body {
  padding: 10px 30px 30px 30px !important;
}
</style>
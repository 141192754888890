<template>
  <div>
    <b-modal
      v-model="isNewsletterSubscriberModal"
      content-class="airspirit-modal"
      hide-footer
      header-class="airspirit-modal-header"
      body-class="newsletter-subscriber-modal-body"
      size="md"
    >
      <template #modal-header>
        <div 
          @click="isNewsletterSubscriberModal = false"
          class="airspirit-modal-header--close-icon"
          style="float: left"
        >
          <img 
            :src="require('@/assets/images/pictos/croix.svg')"
          />
        </div>
        <div
          class="airspirit-modal-header--title"
          style=""
        >
          Sign up to our Newsletter
        </div>
  
      </template>

      <div class="mt-2 text-center">
        <img 
          :src="require('@/assets/images/newsletter/newsletter_icon.svg')"
          style="width: 100px;"
        />
      </div>

      <input
        class="registration-input newsletter-subscriber-modal-input mt-3"
        type="text"
        maxlength="255"
        placeholder="Email address"
        v-model="email"
        :state="$v.email.$dirty ? !$v.email.$error : null"
      >

      <div class="text-danger"
        v-if="errorSaved">
        You are already registered to our newsletter
      </div>
      
      <button
        class="newsletter-subscriber-modal-button mt-3"
        :disabled="$v.$invalid"
        @click="signUpNewsletter"
      >
        Sign up
      </button>
    </b-modal>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, email } from 'vuelidate/lib/validators'
import { APINewsletterSubscriber } from '@/api/APINewsletterSubscriber'

const apiNewsletterSubscriber = new APINewsletterSubscriber()


export default {
  name: 'NewsletterSubscriberModalComponent',
  mixins: [
    validationMixin
  ],
  data: function () {
    return {
      email: '',
      errorSaved: false,
    }
  },
  computed : {
    isNewsletterSubscriberModal: {
      get () {
        return this.$store.state.isNewsletterSubscriberModal
      },
      set () {
        this.$store.commit('closeNewsletterSubscriberModal')
      }
    },
  },
  validations: {
    email: {
      required,
      email
    },
  },
  methods: {
    signUpNewsletter: function () {
      this.$store.commit('loadFromAPI')
      const data = {
        email: this.email.toLowerCase(),
      }
      this.errorSaved = false
      apiNewsletterSubscriber.createNewsletterSubscriber(data)
      .then(() => {
        this.$store.commit('closeNewsletterSubscriberModal')
        this.$bvToast.toast('You are now registered to our newsletter', {
          title: 'Registration validated',
          variant: 'success',
          solid: true
        })
      })
      .catch((error) => {
        console.log(error)
        this.errorSaved = true
      })
      .finally(() => {
        this.$store.commit('endLoadingFromAPI')
        this.email = ''
      })
   },
  }
}
</script>

<style>
.newsletter-subscriber-modal-body {
  padding: 10px 30px 30px 30px !important;
}

.newsletter-subscriber-modal-input {
  border-radius: 5px !important;
}

.newsletter-subscriber-modal-button {
  background-color: white;
  border: 1px solid black;
  border-radius: 5px;
  color: black;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 30px;
  padding-right: 30px;
  font-weight: bold;
  font-size: 1rem;
  width: 100%;
}
</style>
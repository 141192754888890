import { render, staticRenderFns } from "./LanguageMenu.vue?vue&type=template&id=9fb5d736"
import script from "./LanguageMenu.vue?vue&type=script&lang=js"
export * from "./LanguageMenu.vue?vue&type=script&lang=js"
import style0 from "./LanguageMenu.vue?vue&type=style&index=0&id=9fb5d736&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
<template>
  <div 
    v-if="!hideFooter"
    id="footer" 
  >
    <b-modal
      v-model="isPrivatePersonRegisterYourBusinessModal"
      content-class="airspirit-modal"
      hide-footer
      header-class="airspirit-modal-header"
      body-class="airspirit-modal-body"
    >
      <template #modal-header>
        <div 
          @click="cancelPrivatePersonRegisterYourBusinessModal"
          class="airspirit-modal-header--close-icon"
          style="float: left"
        >
          <img 
            :src="require('@/assets/images/pictos/croix.svg')"
          />
        </div>
        <div
          class="airspirit-modal-header--title"
          style=""
        >
          Register your business
        </div>
  
      </template>
      <div class="mt-2 text-center">
        <p>
          You are already logged as {{userFirstName}} {{userLastName}}. Do you want to log out to Register as a Business?
        </p>
      </div>

      <button
        class="airspirit-modal--button"
        @click="cancelPrivatePersonRegisterYourBusinessModal"
      >
        Cancel
      </button>

      <button
        class="airspirit-modal--button mt-2"
        @click="logoutPrivatePersonRegisterYourBusinessModal"
      >
        Logout and sign up to manage a Business
      </button>
    </b-modal>

    <div 
      class="footer-row"
      :style="stickedFooter ? 'margin-top: 20px;' : 'border-top: 1px solid rgb(163, 163, 163);'"
    >
      <div class="footer-column">
        <div class="footer-column--title">
          <strong>AIRspirit</strong>
        </div>
        <div>
          <router-link class="footer-column--link" to="/who-we-are">
            {{$t('footer.who_we_are')}}
          </router-link>
        </div>
        <div>
          <router-link class="footer-column--link" to="/about-features">
            {{$t('footer.learn_new_features')}}
          </router-link>
        </div>
        <!--<div>
          <router-link class="footer-column--link" to="/">
            Letter from founder
          </router-link>
        </div>-->
        <!--<div>
          <router-link class="footer-column--link" to="/">
            Careers
          </router-link>
        </div>
        <div>
          <router-link class="footer-column--link" to="/">
            Investors
          </router-link>
        </div>-->
      </div>
      <div class="footer-column">
        <div class="footer-column--title">
          <strong>{{$t('footer.community')}}</strong>
        </div>
        <div>
          <a class="footer-column--link" href="mailto:info@airspirit.com" target="_blank">
            {{$t('footer.contact_us')}}
          </a>
        </div>
        <div>
          <a
            @click="$store.commit('openDonationModal')"
            class="footer-column--link" 
          >
            {{$t('footer.donate_to_airspirit')}}
          </a>
        </div>
        <!--<div>
          <a
            @click="$store.commit('openNewsletterSubscriberModal')"
            class="footer-column--link" 
          >
            Sign Up to our Newsletter
          </a>
        </div>-->
       <!-- <div>
          <router-link class="footer-column--link" to="/">
            Report an issue
          </router-link>
        </div>-->
      </div>
      <div class="footer-column">
        <div class="footer-column--title">
          <strong>{{$t('footer.do_business_with_us')}}</strong>
        </div>
        <!--<div>
          <a 
            class="footer-column--link" 
            :class="{'footer-column--link--disabled': isAuthenticated}"
            @click="!isAuthenticated && $store.commit('openLoginModal')"
          >
            Login your account
          </a>
        </div>-->
        <div>
          <a
            class="footer-column--link" 
            @click="clickOnRegisterYourBusiness"
          >
            {{$t('footer.register_your_business')}}
          </a>
        </div>
        <div>
          <a
            class="footer-column--link" 
            @click="clickOnSellOnAirspirit"
          >
            {{ $t('footer.sell_on_airspirit') }}
          </a>
        </div>
      </div>
      <div class="footer-column">
        <div class="footer-column--title">
          <strong>{{$t('footer.support_and_ressources')}}</strong>
        </div>
        <!--<div>
          <router-link class="footer-column--link" to="/">
            Help center
          </router-link>
        </div>-->
        <div>
          <router-link class="footer-column--link" to="/privacy">
            {{$t('footer.privacy_policy')}}
          </router-link>
        </div>
        <!--<div>
          <router-link class="footer-column--link" to="/">
            Sitemap
          </router-link>
        </div>-->
        <div>
          <router-link class="footer-column--link" to="/terms">
           {{$t('footer.terms_of_service')}} 
          </router-link>
        </div>
      </div>
    </div>
    <div>
      <div class="footer-copyright text-center mt-2 pb-4">
        AIRspirit © 2024
      </div>
    </div>
  </div>
</template>

<script>
import { userMixins } from '@/mixins/userMixins'


export default {
  name: 'FooterComponent',
  mixins: [userMixins],
  data: () => ({
    footerHiddenRoutes: [
      'company-registration-category',
      'company-registration-company',
      'company-registration-confirm-email',
      'company-registration-company-hq',
      'company-registration-company-geocode',
      'company-registration-company-pictures',
      'company-registration-company-qualifications',
      'company-registration-company-aircraft',
      'company-registration-aircraft-geocode',
      'company-registration-aircraft-pictures',
      'company-registration-validation',
      'private-person-registration',
    ],
    isPrivatePersonRegisterYourBusinessModal: false,
  }),
  computed: {
    language () {
      return this.$i18n.locale
    },
    routeName () {
      return this.$route.name
    },
    hideFooter () {
      return (
        this.footerHiddenRoutes.includes(this.routeName) || (this.routeName && this.routeName.startsWith('admin-'))
      )
    },
    isAuthenticated () {
      return this.$store.getters.isAuthenticated
    },
    isLoginModal () {
      return this.$store.getters.isLoginModal
    },
    isNewsletterSubscriberModal () {
      return this.$store.getters.isNewsletterSubscriberModal
    },
    stickedFooter () {
      return this.$route.name === 'home'
    },
    userFirstName () {
      return this.$store.getters.getUserFirstName
    },
    userLastName () {
      return this.$store.getters.getUserLastName
    },
  },
  methods: {
    cancelPrivatePersonRegisterYourBusinessModal () {
      this.isPrivatePersonRegisterYourBusinessModal = false
      this.$router.push('/account/')
    },
    clickOnRegisterYourBusiness () {
      console.log('COUCOUCOCUOCUOCUOC')
      if (!this.isAuthenticated) {
        this.$router.push('/company/registration/category')
      } else {
        if (this.userGroupContains(['COMPANY'])) {
          this.$router.push('/account/')
        }
        else {
          this.isPrivatePersonRegisterYourBusinessModal = true
        }
      }
    },
    clickOnSellOnAirspirit () {
      if (!this.isAuthenticated) {
        this.$store.commit('openLoginModal')
      } else {
        this.$router.push('/user/account/ads/')
      }
    },
    logoutPrivatePersonRegisterYourBusinessModal () {
      this.isPrivatePersonRegisterYourBusinessModal = false
      this.$store.dispatch('AUTH_LOGOUT').then(() => {
        this.$router.push('/company/registration/category')
      })
    },
    
    
  },
}
</script>

<style>
#footer {
  position: absolute;
  bottom: 0;
  height: 150px; 
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

.footer-row {
  margin-top: 50px;
  padding-bottom: 50px;
  padding-top: 30px;
  padding-left: 15px;
  padding-right: 15x;
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: space-between;
}

@media (min-width: 800px) {
  .footer-row {
    flex-direction: row;
    padding-right: 100px;
    padding-left: 100px;
  }
}

.footer-column--title {
  font-weight: bold;
  color: rgba(37, 37, 37, 0.8);
  margin-bottom: 1rem;
}

.footer-column--link {
  cursor: pointer;
  color: rgba(37, 37, 37, 0.5);
}

.footer-column--link--disabled {
  color: rgba(37, 37, 37, 0.3);
}

.footer-copyright {
  color: rgb(163, 163, 163);
  font-size: 11px;
}

</style>
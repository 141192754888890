import axios from 'axios'
import { apiBaseUrl } from '@/variables/localVariables'


export class APINewsletterSubscriber {

  createNewsletterSubscriber(data) {
    let url = `${apiBaseUrl}/newsletter-subscribers/`
    let headers = {
      'Content-Type': 'application/json',
    }
    return axios.post(url, data, {'headers': headers,})
  }
}

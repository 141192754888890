<template>
  <div class="language-menu">

    <div class="container">
      <b-row cols="4">
        <b-col
          class="p-1 text-center"
          @click="setLanguage('fr')"
        >
          <div 
            class="language-menu-button"
          >
            <div class="language-menu-button-text" :style="languageButtonStyle('fr')">Français</div>
          </div>
          
        </b-col>
        <b-col
          class="p-1 text-center"
          @click="setLanguage('en')"
        >
          <div 
            class="language-menu-button"
          >
            <div class="language-menu-button-text" :style="languageButtonStyle('en')">English</div>
          </div>
          
        </b-col>
        <b-col
          class="p-1 text-center"
          @click="setLanguage('de')"
        >
          <div 
            class="language-menu-button"
          >
            <div class="language-menu-button-text" :style="languageButtonStyle('de')">Deutsch</div>
          </div>
          
        </b-col>
        <b-col
          class="p-1 text-center"
          @click="setLanguage('es')"
        >
          <div 
            class="language-menu-button"
          >
            <div class="language-menu-button-text" :style="languageButtonStyle('es')">Español</div>
          </div>
        </b-col>
      </b-row>
    </div>

    <hr class="mt-3 language-menu-button-border">
  </div>
</template>
<script>
export default {
  name: 'LanguageMenu',
  computed:{
    language () {
      return this.$i18n.locale
    }
  },
  methods: {
    languageButtonStyle(language) {
      return language == this.language ? `color: black;` : ''
    },
    setLanguage(locale) {
      this.$i18n.locale = locale;

      // Optional: Store the locale in localStorage to persist user preference
      localStorage.setItem("locale", locale);

      this.$emit('close-language-menu')
    }
  }
}
</script>
<style>
.language-menu {
  padding-top: 18px;
}
.language-menu-button {
  cursor: pointer;
  position: relative;
}
.language-menu-button-text {
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  color: #DDDDDD;
}
@media (min-width: 800px) {
  .language-menu-button-text  {
    font-size: 16px;
  }
}
</style>
